import React from 'react'
import styles from './style.module.css'

interface IProps {
  type: 500 | 404
  fromUrl?: string
  tips?: string
}
function Exception({ type, fromUrl, tips }: IProps) {
  return (
    <div className={styles['error-container']}>
      <div style={{ width: 180 }}>
        <img src={require('static/404.svg')} />
      </div>
      <div className={styles['content']}>
        <div className={styles['tt']}>
          {type === 404
            ? tips
              ? tips
              : '您要访问的页面弄丢了'
            : '网络发生了异常，请稍后重试！'}
        </div>
        <div className={styles['retry-wrap']}>
          {fromUrl && (
            <a className={styles['btn-retry']} href={fromUrl}>
              <i className={`DCD_Icon icon_replay_24`} />
              点击重试
            </a>
          )}
        </div>
        <div className={styles['recommend']}>
          懂车帝为您推荐其它精彩内容{type === 404 ? '，以下都没有' : ''}
          {type === 404 && (
            <a
              className={styles['feedback']}
              onClick={() => {
                PubSub.publish('OPEN_FEEDBACK')
              }}
            >
              我要反馈我想找的内容
            </a>
          )}
        </div>
        <ul className={styles['nav-wrap']}>
          <li>
            <a href="/auto/library/x-x-x-x-x-x-x-x-x-x-x">
              <span>选车</span>
            </a>
          </li>
          <li>
            <a href="/feed">
              <span>资讯</span>
            </a>
          </li>
          <li>
            <a href="/video">
              <span>视频</span>
            </a>
          </li>
          <li>
            <a href="/live">
              <span>直播</span>
            </a>
          </li>
          <li>
            <a href="/car_fans_community">
              <span>车友圈</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Exception
