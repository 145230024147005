import * as React from 'react'
import { useRouter } from 'next/router'
import Exception from 'Components/exception'
import { metrics } from 'lib/slardar-utils'
import qs from 'qs'

function PageNotFound() {
  // (1) 基本数据
  const router = useRouter()
  const [fromUrl, setFromUrl] = React.useState<string>()

  /** (2) 提示文本 */
  const tips = React.useMemo(() => {
    if (/^\/usedcar\/(\d+)$/.test(router.asPath)) {
      return '您访问的二手车已售出或下架'
    }
  }, [])

  // (3) 初始化
  React.useEffect(() => {
    metrics.counter('404', {
      from_error_page: false,
    })
    const { search } = window.location
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    if (query?.from) {
      setFromUrl(encodeURI(query?.from as string))
    }
  }, [])

  return (
    <section className="page-404">
      <Exception type={404} fromUrl={fromUrl} tips={tips} />
    </section>
  )
}

export default PageNotFound
